.container {
  position: relative;
}
.image {
  position: relative;
  overflow: hidden;
}
.image > img {
  object-fit: cover;
}
.icon {
  position: absolute;
  top: 10px;
  right: 10px;
  z-index: 2;
}

.card {
  background-color: var(--mantine-color-white);
}

.section {
  padding-left: var(--mantine-spacing-md);
  padding-right: var(--mantine-spacing-md);
  padding-bottom: var(--mantine-spacing-md);
}
