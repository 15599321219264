.main {
  /* min-height: calc(100dvh - (rem(57) * 2 + rem(82))); */
  background-color: var(--mantine-color-gray-0);
  flex: 1;
  overflow-y: auto;
}

.layout {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  min-height: 100dvh;
  height: 100vh;
  height: 100dvh;
  overflow: hidden;
}

.header {
  position: sticky;
  top: 0;
  z-index: 100;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.footer {
  position: sticky;
  top: 100vh;
  top: 100dvh;
  z-index: 100;
}
