.footer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  border-top: 1px solid var(--mantine-color-gray-3);
  background-color: var(--mantine-color-gray-0);
  height: rem(56);
  max-height: rem(56);
}

.inner {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: var(--mantine-spacing-xs) var(--mantine-spacing-xs);
}
@media (max-width: $mantine-breakpoint-sm) {
  .inner {
    flex-direction: column;
    padding: var(--mantine-spacing-xs) var(--mantine-spacing-xs);
  }
}

.link {
  text-decoration: none;
  color: var(--mantine-color-black);
}
