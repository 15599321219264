.footer {
  background-color: var(--mantine-color-blue-9);
  border-top: 1px solid var(--mantine-color-gray-3);
}

.inner {
  height: rem(56px);
  max-height: rem(56px);
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
  padding: var(--mantine-spacing-md) var(--mantine-spacing-md);
}

@media (max-width: 40em) {
  .inner {
    flex-direction: column;
    padding: var(--mantine-spacing-xs) var(--mantine-spacing-xs);
  }
}

.link {
  text-decoration: none;
  color: var(--mantine-color-black);
}
