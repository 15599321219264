.root {
  --max-width: 391px;
  --max-height: 845px;
  --footer-height: 70px;
  --logo-height: 102px;
  --inner-padding-space: var(--modal-inner-y-offset, var(--modal-y-offset));
}

.modalBody {
  /* height: 100%; */
  padding: 0;
  background-size: cover;
  background-position: center;
  background-image: url("/images/confirm_modal/background.png");
  position: relative;
  height: calc(100vh - var(--footer-height));
  max-height: calc(
    var(--max-height) - var(--footer-height) - var(--inner-padding-space)
  );
}

.modalBodyFull {
  /* height: 100%; */
  padding: 0;
  background-size: cover;
  background-position: center;
  background-image: url("/images/confirm_modal/background.png");
  position: relative;
  height: calc(100vh - var(--footer-height));
  max-height: calc(calc(100vh - var(--footer-height)));
}
.modalContent {
  overflow: hidden;
  /* aspect-ratio: 387 / 932; */
}

.logo {
  width: 100%;
  height: var(--logo-height);
  display: flex;
  justify-content: center;
}

.logo img {
  max-width: 100%;
  max-height: 100%;
  width: auto;
  height: auto;
  object-fit: contain;
}

.main {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  height: calc(100% - var(--logo-height));
  max-height: calc(
    var(--max-height) - var(--footer-height) - var(--logo-height)
  );
  padding: 50px 0;
}

.money {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  gap: 80px;
}

.text {
  font-size: 30px;
  color: white;
  font-weight: bold;
  margin: 0;
}

.amountText {
  font-size: 50px;
  color: white;
  font-weight: bold;
  margin: 0;
  padding-bottom: 30px;
}

.logo {
  width: 100%;
  height: var(--logo-height);
  display: flex;
  justify-content: center;
}

.main {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  height: calc(100% - var(--logo-height));
  max-height: calc(
    var(--max-height) - var(--footer-height) - var(--logo-height)
  );
  padding: 50px 0;
}

.money {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  gap: 80px;
}

.text {
  font-size: 30px;
  color: white;
  font-weight: bold;
  margin: 0;
}

.amountText {
  font-size: 50px;
  color: white;
  font-weight: bold;
  margin: 0;
  padding-bottom: 30px;
}
