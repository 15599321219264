.container {
  position: relative;
}
.image {
  position: relative;
  overflow: hidden;
}
.image > img {
  object-fit: cover;
}

.card {
  background-color: var(--mantine-color-white);
}

.section {
  border-bottom: rem(1) solid var(--mantine-color-gray-3);
  padding-left: var(--mantine-spacing-md);
  padding-right: var(--mantine-spacing-md);
  padding-bottom: var(--mantine-spacing-md);
}

.badge {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
}
