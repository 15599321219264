.main {
  min-height: calc(100dvh - (rem(57) * 2));
  background-color: var(--mantine-color-gray-0);
}
.title {
  width: 100%;
  position: fixed;
  top: 56px;
  z-index: 100;
  background-color: var(--mantine-color-gray-0);
  border-bottom: 1px solid var(--mantine-color-gray-3);
  transform: translateY(0);
  opacity: 1;
  transition:
    transform 0.3s ease-in-out,
    opacity 0.3s ease-in-out;
}

.titleHidden {
  transform: translateY(-56px);
  pointer-events: none;
  opacity: 0;
}
