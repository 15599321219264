.dateItem {
  height: rem(55px);
  display: flex;
  align-items: center;
  justify-content: space-around;
  flex-direction: column;
  cursor: pointer;
  padding-left: rem(10px);
  padding-right: rem(10px);
  border-radius: var(--mantine-radius-md);
}
.dateItem[data-selected] {
  background-color: rgb(231, 245, 255);
}

@media (max-width: 40em) {
  .dateItem {
    padding-left: rem(10px);
    padding-right: rem(10px);
  }
}
