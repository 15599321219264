.container {
  position: absolute;
  width: 100%;
  bottom: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  background: #ff6b6b;
  padding: 4px 12px 4px 4px;
  z-index: 1;
}

.badge {
  width: 20px;
  height: 20px;
}

.text {
  color: var(--mantine-color-white);
  font-weight: bold;
  font-size: 12px;
}
