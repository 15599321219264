.dateItem {
  display: flex;
  justify-content: center;
}

@media (max-width: 48em) {
  .paper {
    padding-left: 0;
    padding-right: 0;
  }
}
.container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.scrollContainer {
  display: flex;
  overflow-x: auto;
  overflow-y: hidden;
  scroll-behavior: smooth;
  padding: 0 10px;
  scrollbar-width: none;
  -ms-overflow-style: none;
  max-width: var(--container-size-sm);
}

.scrollContainer::-webkit-scrollbar {
  display: none;
}
