@media (max-width: $mantine-breakpoint-sm) {
  .paper {
    padding-left: 0;
    padding-right: 0;
  }
}
.dateItem {
  display: flex;
  justify-content: center;
}
